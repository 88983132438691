<template>
      <v-dialog
          v-model="dialog"
          width="500"
          fullscreen
          hide-overlay
      >
        <template v-slot:activator="{on}">
          <v-btn v-on="on" elevation="2" color="success">
            Access
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Select Products for Re-Submission</v-card-title>
          <v-card-subtitle>{{ fileName }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
              <v-simple-table>
                <template v-slot:default>
              <thead>
              <tr>
                <th>Select</th>
                <th>license_number</th>
                <th>inventory_category</th>
                <th>inventory_type</th>
                <th>name</th>
                <th>description</th>
                <th>unit_weight_grams</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in products" :key="item.id">
                <td><v-checkbox @change="selectProduct(item.id, $event)"></v-checkbox></td>
                <td>{{ item.license_number }}</td>
                <td>{{ item.inventory_category }}</td>
                <td>{{ item.inventory_type }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.unit_weight_grams }}</td>
              </tr>
              </tbody>
                </template>
              </v-simple-table>
            <v-btn @click="reSubmit" :loading="loading" color="success">Add to Re-Submits</v-btn>
            <v-btn @click="dialog=false" :loading="loading" color="warning">Cancel</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";
import dateFormatter from "@/lib/dateFormatter";

export default {
  name: "EditFileProducts",
  props: ['fileName'],
  data: () => ({
    loading: false,
    dialog: false,
    fileType: '',
    contentType: '',
    products: [],
    selectedProducts: [],
  }),
  watch: {
    dialog(value) {
      if (value) {
        this.loadFileType();
        this.loadProductsData();
      }
    }
  },
  methods: {
    loadFileType() {
      let fileType = '';
      let contentType = '';
      if (this.fileName.includes('labtest')) {
        fileType = 'labResults';
        contentType = 'samples';
      } else {
        fileType = 'labProducts';
        contentType = 'products';
      }
      this.fileType = fileType;
      this.contentType = contentType;
    },
    async loadProductsData() {
      this.loading = true;
      let query = await ccrsApi.get('/v1/' + this.fileType + '/fileName/' + this.contentType + '/' + this.fileName);
      this.products = query.data;
      this.loading = false;
    },
    async reSubmit() {
      this.loading = true;
      let query = await ccrsApi.post('/v1/' + this.fileType + '/reSubmit/' + this.contentType, this.selectedProducts);
      this.analyteData = query.data;
      this.loading = false;
      this.dialog = false;
    },
    selectProduct(value, event) {
      if(event && !this.selectedProducts.includes(value)){
        this.selectedProducts.push(value);
      } else if (!event && this.selectedProducts.includes(value)) {
        let index = this.selectedProducts.findIndex(id => id === value);
        if(index > -1) this.selectedProducts.splice(index, 1)
      }
    },
    formattedDate(dateString) {
      return dateFormatter.formatDate(dateString);
    },
  },
}
</script>

<style scoped>

</style>